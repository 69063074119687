@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  list-style: none;
  scroll-behavior: smooth;
}

@font-face {
  font-family: okatah-500;
  src: url("./fonts/okatah-500.ttf");
}

@font-face {
  font-family: okatah-300;
  src: url("./fonts/okatah-300.otf");
}

@font-face {
  font-family: okatah-700;
  src: url("./fonts/okatah-700.otf");
}

@font-face {
  font-family: okatah-900;
  src: url("./fonts/okatah-900.otf");
}

:root{
  --blue-dark:#1B3A71;
  --blue-light:#1273C4;
  --cyan:#22E6F2;
  --cyan-light:#F2FBF8;
  --yellow:#F4DD0C;
  --gray-dark:#515151;
  --bluish:#F2FBF8;
}

.poppin-700{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.poppin-600 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.poppin-500 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.modal{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.613);
  z-index: 99;
}

.styledList li{
  list-style: disc !important;
}

.menu-open {
  animation: openMenu 0.3s linear;
  transform: translate(0%, 1%);
}

@keyframes openMenu {
  0% {
    transform: translate(100%, 1%);
  }

  50% {
    transform: translate(50%, 1%);
  }

  100% {
    transform: translate(0%, 1%);
  }
}

.close-menu {
  animation: closeMenu 0.3s linear;
  transform: translate(-400%, 1%);
}


@keyframes closeMenu {
  0% {
    transform: translate(0%, 1%);
  }

  50% {
    transform: translate(80%, 1%);
  }

  100% {
    transform: translate(160%, 1%);
  }
}


.banner{
  background-image: url("./Assets/Images/headerbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.store{
  background-color: rgba(0, 0, 0, 0.5);
}


.image{
  position: relative;
  border-radius: 15px;
}

.image::after{
  content: "";
  position: absolute;
  top: 11%;
  left: 12%;
  width: 90%;
  height: 90%;
  z-index: 4;
  border-radius: 15px;
  background-color: #EFF1F4;
}